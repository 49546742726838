import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/paratriennale/paratriennale/loveletter/src/templates/works-page-layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const FixedRadio = makeShortcode("FixedRadio");
const Caption = makeShortcode("Caption");
const BpHr = makeShortcode("BpHr");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`ジェス・トム`}</h1>
    <p>{`トゥレット症候群（チックの一種）をもつジェス・トムがサミュエル・ベケットの《わたしじゃないし》を上演するまでの過程を描いたドキュメンタリー映画です。《わたしじゃないし》は、暗闇の舞台上に宙吊りにされた役者の口だけが露出した「口」という登場人物が、ほとんど意味をなさない言葉を矢継ぎ早に発し続ける約 12 分間の作品です。ほぼ発語のみに焦点が置かれた作品を発語に障害のある当事者が演じるという前代未聞のチャレンジに、ユーモアと好奇心で立ち向かっていくジェス・トムの姿が描かれています。`}</p>
    <FixedRadio mdxType="FixedRadio"><div className="stretchCenterText">11月24日23:59をもって公開終了いたしました。</div></FixedRadio>
    <Caption before={`作品`} medium={`映画　60分　監督：ソフィー・ロビンソン、2018年`} mdxType="Caption">
  《Me, My Mouth and I》
    </Caption>
    <span className="fontLvBodyS">
  ＊
  一部過激な言語表現が含まれますが、それは主人公の障害の一部であり、彼女が日常的に経験していることを伝える要素であるため、そのままにしています。予めご了承の上、ご鑑賞ください。
    </span>
    <BpHr type={`dot`} mdxType="BpHr" />
    <FixedRadio mdxType="FixedRadio">
  <iframe width="560" height="315" src="https://www.youtube.com/embed/-mb7RTXTizY" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    </FixedRadio>
    <p>{`11 月 21 日に、ジェス・トムさんをお迎えしてオンライン・トークを行いました。
その様子がこちらでご覧いただけます。`}</p>
    <BpHr type={`dot`} mdxType="BpHr" />
    <img src="/images/uk-japan-logo.png" alt="" width="200" style={{
      "marginTop": "2em"
    }} />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      